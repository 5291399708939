import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/global/layout/Layout';
import Img from 'gatsby-image';
import * as S from '../components/integrations-page/styles';
import CTA from '../components/global/CTA/CTA';
import SEO from '../components/global/seo';
import { CONSTANTS } from '../../utils/constants';
import { Container } from '../components/global/customers/styles';
import DropIcon from '../svg/drop-down-arrow.svg';

const Integrations = ({ data }) => {
	const [state, setstate] = useState('');
	const [category, setCategory] = useState(CONSTANTS.ALL_COLLECTIONS);
	const [isClose, setIsClose] = useState(true);

	const {
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
		integrationsList,
		section1Image,
		integrationLabels,
		section1Subtitle,
		section1Title,
		section3Title,
		section3Subtitle,
	} = data.allContentfulIntegrationsPage.nodes[0];

	const findIntegrations = (x, y) => {
		if (x === CONSTANTS.ALL_COLLECTIONS) {
			setstate('');

			if (!y) {
				setCategory(x);
			}
		} else {
			const data = integrationLabels.map(element => {
				return element.data.map(item =>
					integrationsList.filter(image => image.title === item.name),
				);
			});
			setstate(data[x]);
			setCategory(y);
		}
	};

	const fetchSelectedOption = x => {
		if (x === CONSTANTS.ALL_COLLECTIONS) {
			setstate('');
			setCategory(CONSTANTS.ALL_COLLECTIONS);
		} else {
			const data = integrationLabels.map(element => {
				return element.data.map(item =>
					integrationsList.filter(image => image.title === item.name),
				);
			});

			setstate(data[x]);

			setCategory(integrationLabels[x].title);
		}
		setIsClose(!isClose);
	};

	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<Container>
				<S.Wrapper>
					<S.HeaderImg style={{ width: '100%' }}>
						<Img fluid={section1Image.fluid} alt={section1Image.title} />
					</S.HeaderImg>
					<S.SectionTextBox>
						<div>
							<h1 className="font-black-54">{section1Title}</h1>
							<S.SubText className="font-book-18">{section1Subtitle}</S.SubText>
						</div>
					</S.SectionTextBox>
				</S.Wrapper>
			</Container>
			<S.BgWrapper>
				<Container>
					<S.IntegrationsHolder>
						<div className="buttons-holder">
							<p className="categories">Categories</p>
							<button
								onClick={() => findIntegrations(CONSTANTS.ALL_COLLECTIONS)}
								className={category === CONSTANTS.ALL_COLLECTIONS && 'active'}
							>
								{CONSTANTS.ALL_COLLECTIONS}
							</button>
							{integrationLabels.map(label => (
								<button
									onClick={() => findIntegrations(label.index, label.title)}
									className={label.title === category && 'active'}
								>
									{label.title}
								</button>
							))}
						</div>
						<S.IntegrationDropdwon>
							<p className="categories-2">Categories</p>
							<S.Select onClick={e => setIsClose(!isClose)}>
								<S.Category className={'selected-category'}>
									{category}
								</S.Category>
								<DropIcon className="dropdown-arrow" />
							</S.Select>
							<S.Options className={isClose ? 'hide' : ''}>
								<S.Category
									className={CONSTANTS.ALL_COLLECTIONS === category && 'hide'}
									onClick={e => fetchSelectedOption(CONSTANTS.ALL_COLLECTIONS)}
								>
									{' '}
									{CONSTANTS.ALL_COLLECTIONS}
								</S.Category>
								{integrationLabels.map((label, index) => (
									<S.Category
										className={label.title === category && 'hide'}
										onClick={e => fetchSelectedOption(index)}
									>
										{' '}
										{label.title}
									</S.Category>
								))}
							</S.Options>
						</S.IntegrationDropdwon>
						<S.Integrations>
							<p className="selected">{category}</p>
							<S.Holder>
								{(state &&
									state.map(integration => (
										<div className="integration-card">
											<div className="application-card">
												<div className="img-container">
													<Img
														fixed={integration[0].fixed}
														alt={integration[0].title}
													/>
												</div>
												<S.IntegrationTitle>
													{integration[0].title}
												</S.IntegrationTitle>
												<S.IntegrationDescription>
													{integration[0].description}
												</S.IntegrationDescription>
											</div>
										</div>
									))) ||
									integrationsList.map(integration => (
										<div className="integration-card">
											<div className="application-card">
												<div className="img-container">
													<Img
														fixed={integration.fixed}
														alt={integration.title}
													/>
												</div>
												<S.IntegrationTitle>
													{integration.title}
												</S.IntegrationTitle>
												<S.IntegrationDescription>
													{integration.description}
												</S.IntegrationDescription>
											</div>
										</div>
									))}
							</S.Holder>
						</S.Integrations>
					</S.IntegrationsHolder>
				</Container>
			</S.BgWrapper>
			<S.CTAWrapper>
				<CTA title={section3Title} subtitle={section3Subtitle} />
			</S.CTAWrapper>
		</Layout>
	);
};

export const query = graphql`
	{
		allContentfulIntegrationsPage {
			nodes {
				seoTitle
				seoDescription
				seoArticle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
				section1Title
				section1Subtitle
				section1Image {
					fluid(maxWidth: 2000, maxHeight: 475) {
						...GatsbyContentfulFluid
					}
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				section3Title
				section3Subtitle
				integrationLabels {
					data {
						description
						name
					}
					title
					index
				}
				integrationsList {
					title
					description
					fixed(width: 70) {
						...GatsbyContentfulFixed
					}
				}
			}
		}
	}
`;

export default Integrations;
